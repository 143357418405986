import React from 'react'
import Img from 'gatsby-image'

// Images
import phone from 'img/phone.svg'
import mail from 'img/mail.svg'

// Third Party
import styled from 'styled-components'

const StyledEmployee = styled.div``

const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;
  border-radius: 20px;
`

const EmployeeContent = styled.div`
  right: 0;
  bottom: -50px;
  border-bottom-left-radius: 20px;

  @media (min-width: 992px) {
    min-width: 220px;
  }

  @media (max-width: 991px) {
    width: 100%;
  }
`

const EmployeeContainer = styled.div`
  width: 300px;
  height: 300px;
`

const Employee = ({ className, employee}) => (
  <StyledEmployee className={`d-flex my-5 justify-content-center ${className ? `${className}` : ``}`}>
    <EmployeeContainer className="position-relative">
      <StyledImg fluid={employee.image.localFile.childImageSharp.fluid} alt="" />
      <EmployeeContent className="px-3 px-lg-4 py-2 position-absolute color-background-contrast">
        <h2 className="font-family-secondary-alt font-size-m color-text-light mb-0">{employee.name}</h2>
        <p className="font-size-sm color-text-secondary mb-2 font-weight-xl">{employee.function}</p>
        <a href={`mailto:${employee.email}`} className="text-white d-block mb-1">
          <img src={mail} width="15" alt="Mail ons" />
          <span className="ml-2">{employee.email}</span>
        </a>
        <a href={employee.phone.url} className="text-white d-block mb-1">
          <img src={phone} width="15" alt="Neem contact op" /> 
          <span className="ml-2">{employee.phone.title}</span>
        </a>
      </EmployeeContent>
    </EmployeeContainer>
  </StyledEmployee>
)

export default Employee