/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import parse, { domToReact } from 'html-react-parser'

// Elements
import ButtonDefault from 'components/elements/ButtonDefault'


const StyledHeroDefault = styled.div`
  @media screen and (min-width: 992px) {
    height: 300px;
  }

  @media screen and (max-width: 991px) {
    height: 300px;
  }

  @media screen and (max-width: 575px) {
    height: 250px;
  }
`

const Content = styled.div`
  position: relative;
  z-index: 2;
  border-bottom-left-radius: 20px;
  margin-bottom:0;

  @media screen and (max-width: 991px) {
    width: 100%;
  }

  @media screen and (max-width: 575px) {
    h1 {
      font-size: ${props => props.theme.font.size.xxl}
    }

    h2 {
      font-size: ${props => props.theme.font.size.l}
    }

    p {
      font-size: ${props => props.theme.font.size.s}
    }
  }
`

const HeroNoImage = ({ title, content, className, }) => (
  <StyledHeroDefault className={className}>
    <div className="container d-flex align-items-center h-100">
      <div className="row">
        <Content className="col-lg-6 pb-3 pb-lg-4 p-lg-4">
          {parse(title, {
            replace: domNode => {
              if(domNode.name === 'a') {
                const attributes = domNode.attribs

                if(attributes.style) {
                  delete attributes.style
                }
          
                return (
                  <ButtonDefault to={attributes.href}>
                    {domToReact(domNode.children, {})}
                  </ButtonDefault>
                )
              }

              if(domNode.name === 'h1') {
                  const attributes = domNode.attribs

                  if(attributes.style) {
                    delete attributes.style
                  }

                  return (
                    <h1 className="font-size-xxxl color-text-soft font-family-secondary-alt">
                      {domToReact(domNode.children, {})}
                    </h1>
                  )
                }

                if(domNode.name === 'h2') {
                  const attributes = domNode.attribs

                  if(attributes.style) {
                    delete attributes.style
                  }

                  return (
                    <h2 className="font-size-xxl color-text-soft font-family-secondary-alt">
                      {domToReact(domNode.children, {})}
                    </h2>
                  )
                }

              return true
            },
          })}
        </Content>
        <Content className="col-lg-6 p-lg-4 d-flex align-items-center">
          {parse(content, {
            replace: domNode => {
              if(domNode.name === 'a') {
                const attributes = domNode.attribs

                if(attributes.style) {
                  delete attributes.style
                }
          
                return (
                  <ButtonDefault to={attributes.href}>
                    {domToReact(domNode.children, {})}
                  </ButtonDefault>
                )
              }

              if(domNode.name === 'p') {
                  const attributes = domNode.attribs

                  if(attributes.style) {
                    delete attributes.style
                  }

                  return (
                    <p className="font-size-sm color-text-dark">
                      {domToReact(domNode.children, {})}
                    </p>
                  )
                }

              return true
            },
          })}
        </Content>
      </div>
    </div>
  </StyledHeroDefault>
)

export default HeroNoImage
