import React from 'react'
import Img from 'gatsby-image'

// Components
import ButtonDefault from 'components/elements/ButtonDefault'

// Third Party
import styled, { css } from 'styled-components'
import parse, { domToReact } from 'html-react-parser'

const StyledAboutItem = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;

  ${props => props.right ? css`
    /* Do nothing */
  ` : css`
    background-color: ${props.theme.color.soft};
  `}

  @media screen and (max-width: 767px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`

const StyledImg = styled(Img)`
  position: absolute !important;
  height: 550px;
  width: 50%;
  z-index: 5;
  
  ${props => props.right ? css`
    right: 0;
    border-bottom-left-radius: 20px;
  ` : css`
    left: 0;
    border-bottom-right-radius: 20px;
  `}

  @media screen and (max-width: 991px) {
    width: 65%;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 300px;
    position: relative !important;
    border-radius: 0;
  }

  @media screen and (max-width: 575px) {
    height: 200px;
  }
`

const AboutItemContainer = styled.div`
  height: 550px;

  @media screen and (max-width: 767px) {
    height: 525px;
  }

  @media screen and (max-width: 575px) {
    height: 425px;
  }

  @media screen and (max-width: 440px) {
    height: 475px;
  }
`

const Wrapper = styled.div`
  @media screen and (max-width: 767px) {
    margin-right: 0;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    max-width: unset;
  }
`

const AboutItemContent = styled.div`
  position: relative;
  z-index: 10;
  max-width: 600px;
  box-shadow: 0px 0px 4px #00000080;

  ${props => props.right ? css`
    border-bottom-left-radius: 20px;
  `
  : css`
    border-bottom-right-radius: 20px;
  `}

  @media screen and (max-width: 767px) {
    max-width: unset;
    width: 100%;
  }
`

const AboutItemContentTitle = styled.div`
  height: 50px;

  @media screen and (max-width: 575px) {
    h2 {
      font-size: ${props => props.theme.font.size.xm};
    }
  }
`

const AboutItemContentText = styled.div`
  line-height: 29px;

  @media screen and (max-width: 575px) {
    line-height: 24px;

    p {
      font-size: ${props => props.theme.font.size.s};
    }
  }
`

const AboutItem = ({ right, className, item }) => (
  <StyledAboutItem right={right} className={`position-relative ${className ? `${className}` : ``}`}>
    <AboutItemContainer className="d-md-flex d-block align-items-center px-3 px-lg-0">
      <StyledImg right={right} fluid={item.image.localFile.childImageSharp.fluid} alt="" />
      <Wrapper className={`${right ? `justify-content-start` : `justify-content-end`} d-block d-md-flex container`}>
        <AboutItemContent right={right} className="color-background-light">
          <AboutItemContentTitle className="d-flex align-items-center color-background-contrast">
            <h2 className="mb-0 px-3 px-sm-5 px-lg-5 font-family-secondary-alt color-text-light">{item.title}</h2>
          </AboutItemContentTitle>
          <AboutItemContentText className="px-3 px-sm-5 px-lg-5 py-3 py-md-3 py-lg-5">
            {parse(item.description, {
              replace: domNode => {
                if(domNode.name === 'a') {
                  const attributes = domNode.attribs

                  if(attributes.style) {
                    delete attributes.style
                  }
            
                  return (
                    <ButtonDefault to={attributes.href}>
                      {domToReact(domNode.children, {})}
                    </ButtonDefault>
                  )
                }

                if(domNode.name === 'p') {
                    const attributes = domNode.attribs

                    if(attributes.style) {
                      delete attributes.style
                    }

                    return (
                      <p className="font-size-sm color-text-dark">
                        {domToReact(domNode.children, {})}
                      </p>
                    )
                  }

                return true
              },
            })}
          </AboutItemContentText>
        </AboutItemContent>
      </Wrapper>
    </AboutItemContainer>
    
  </StyledAboutItem>
)

export default AboutItem