/* eslint-disable react/no-array-index-key */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import HeroNoImage from 'components/elements/HeroNoImage'
import Employee from 'components/elements/Employee'
import AboutItem from 'components/elements/AboutItem'
import TitleDefault from 'components/elements/TitleDefault'

// Third Party
import styled from 'styled-components'

const AboutUsContent = styled.div``

const OveronsTemplate = ({
  data: {
    page: { 
      path,
      acf: {
        banner_group: bannerGroup,
        employees,
        inhoud,
        inhoud_title: inhoudTitle
      },
      yoastMeta
    },
  }
}) => {

  return (
    <Layout>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} article />
      <div className="container pt-5 py-lg-5">
        <HeroNoImage content={bannerGroup.text} title={bannerGroup.title} />

        <div className="row py-3 py-lg-5">
          {employees.map((employee, index) => (
            <Employee key={index} className="col-md-4" employee={employee} />
          ))}
        </div>
      </div>

      <AboutUsContent className="pt-lg-5">
        <div className="container">
          <TitleDefault stripe>
            {inhoudTitle}
          </TitleDefault>
        </div>
        {inhoud.map((item, index) => (
          <AboutItem right={(index % 2 === 0)} key={index} item={item} />
        ))}
      </AboutUsContent>

    </Layout>
  )
}

export default OveronsTemplate

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      acf {
        banner_group {
          title
          text
        }
        employees {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          name
          function
          email
          phone {
            title
            url
          }
        }
        inhoud_title
        inhoud {
          title
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
  }
`
